:root {
  --primary-button-color: #183464;
  --text-color: #475467;;
  --text-header-color: #101828;;
}

.p-datatable-header {
  border-width: 0 !important;
}
.p-column-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #475467;
}